import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "How Items Flow Into a CSS Grid",
  "date": "2017-11-24",
  "promo": "grids",
  "description": "the CSS Grid Item Placement Algorithm - Visualized",
  "color": "#e5cb8a"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There you are, happily working on a brand new Grid layout - this powerful new tool making you feel like `}<em parentName="p">{`The Rock`}</em>{` of UI developers - when suddenly you change a single item's position and it throws the entire thing into a jumbled mess of blocks and spaces that would make the hardest level in Tetris look easy.`}</p>
    <blockquote>
      <p parentName="blockquote">{`What the junk, how did `}<strong parentName="p">{`that`}</strong>{` get `}<strong parentName="p">{`there`}</strong>{`?! `}</p>
    </blockquote>
    <p>{`Being skilled with Grid layout takes a lot more than just memorizing syntax. One critical part to understand is how the browser places items into your Grid. The modern way to size and position things in CSS is using `}<a parentName="p" {...{
        "href": "https://gedd.ski/post/naming-css-grid-lines/"
      }}>{`Grid Lines`}</a>{`. But there's another piece of magic at work here - an algorithm that calculates which items to prioritize placing first, and exactly `}<em parentName="p">{`how`}</em>{` to flow everything into the Grid.`}</p>
    <h2>{`Grid Item Placement - Visualized`}</h2>
    <p>{`The CSS spec calls it the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-grid-1/#auto-placement-algo"
      }}>{`Grid Item Placement Algorithm`}</a>{`. Unlike all the other standards specs, this one is massive and complex (zing)! `}</p>
    <p>{`I wanted students of `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` to come away with a solid understanding and intuition around this important aspect of Grid layout. So I built a spec-compliant `}<strong parentName="p">{`animated placement engine`}</strong>{` into `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`my game`}</a>{` that `}<em parentName="p">{`shows you`}</em>{` this algorithm in action. `}</p>
    <p>{`I'll use examples from it here to illustrate the basics of how grid items flow into a `}<inlineCode parentName="p">{`4x3`}</inlineCode>{` Grid:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`planet {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
`}</code></pre>
    <p>{`The green `}<span className="terrain grass"></span>{` terrain items we'll use are stacked to the left of the shuttle to show their order in the DOM.`}</p>
    <h2>{`Auto`}</h2>
    <p>{`Grid items' default behavior of flowing into a Grid is called `}<em parentName="p">{`auto placement`}</em>{`. The items fill up all the slots in the current `}<em parentName="p">{`row`}</em>{` before moving on to fill the next row. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`planet {
  grid-auto-flow: row; /* default */
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gif/grid-item-placement/1.gif",
        "alt": "grid items default to auto"
      }}></img></p>
    <p>{`You can change the `}<inlineCode parentName="p">{`grid-auto-flow`}</inlineCode>{` property to `}<inlineCode parentName="p">{`column`}</inlineCode>{` to make your Grid to fill up `}<strong parentName="p">{`columns first`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`  planet {
    grid-auto-flow: column;
  }
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gif/grid-item-placement/2.gif",
        "alt": "grid items in a grid with grid-auto-flow set to column"
      }}></img></p>
    <h2>{`Placed Grid Items`}</h2>
    <p>{`There are a `}<em parentName="p">{`ton`}</em>{` of ways to place grid items in a grid, using the `}<inlineCode parentName="p">{`grid-row-start`}</inlineCode>{`, `}<inlineCode parentName="p">{`grid-row-end`}</inlineCode>{`, `}<inlineCode parentName="p">{`grid-column-start`}</inlineCode>{`, `}<inlineCode parentName="p">{`grid-column-end`}</inlineCode>{`, `}<inlineCode parentName="p">{`grid-row`}</inlineCode>{`, `}<inlineCode parentName="p">{`grid-column`}</inlineCode>{`, and `}<inlineCode parentName="p">{`grid-area`}</inlineCode>{` properties. The `}<em parentName="p">{`Grid Item Placement algorithm`}</em>{` gives top placement priority though to any item whose `}<strong parentName="p">{`row start`}</strong>{` or `}<strong parentName="p">{`row end`}</strong>{` is defined. In these examples I'll use a green rocky `}<span className="terrain rocky"></span>{` terrain for grid items that are being row-positioned and given priority placement.`}</p>
    <h3>{`row start`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`rocky {
  grid-row-start: 2;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gif/grid-item-placement/3.gif",
        "alt": "grid items with grid-row-start property set"
      }}></img></p>
    <p>{`These rocky `}<span className="terrain rocky"></span>{` terrains are number 3, 6, and 10 in the document (shown stacked to the left of the shuttle). But when they flow into the Grid, the browser places them `}<strong parentName="p">{`first`}</strong>{` since they have a starting grid row specified. After they're in place, all the other items flow in around them.`}</p>
    <h3>{`row end`}</h3>
    <p>{`Items with an `}<strong parentName="p">{`ending grid row`}</strong>{` are given equally high placement priority:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`rocky {
  grid-row-end: 3;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gif/grid-item-placement/4.gif",
        "alt": "grid items with grid-row-start property set"
      }}></img></p>
    <h3>{`row span`}</h3>
    <p>{`It's important to note that priority placement only happens for row `}<strong parentName="p">{`start`}</strong>{` and `}<strong parentName="p">{`end`}</strong>{`. You can make items taller by telling them to `}<strong parentName="p">{`span`}</strong>{` multiple rows, but they'll still have to wait their turn to flow into the grid. When it's their turn, the browser makes them span two rows like they wanted, marking those cells as `}<strong parentName="p">{`taken`}</strong>{`, causing future grid items to flow around them.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`rocky {
  grid-row: span 2;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gif/grid-item-placement/5.gif",
        "alt": "grid items with a grid span"
      }}></img></p>
    <h3>{`column positioning`}</h3>
    <p>{`You can position grid items to start/end in specific columns. Column positioning won't get priority placement, but when it's the item's turn the browser will honor the requested positions and `}<strong parentName="p">{`skip`}</strong>{` to the specified column. This can leave empty cells in the grid which we'll talk about in a second.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`rocky {
  grid-column-start: 3;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gif/grid-item-placement/6.gif",
        "alt": "grid items with grid-column-start property set"
      }}></img></p>
    <h3>{`column flow priority`}</h3>
    <p>{`Remember how you can set your Grid to flow as columns instead of rows? When you do that, now `}<strong parentName="p">{`column`}</strong>{` start/end is given top priority, and row start/end isn't.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`rocky {
  grid-column-start: 3;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gif/grid-item-placement/column-priority-on-column.gif",
        "alt": "grid items with grid-column-start property get priority in a column based Grid"
      }}></img></p>
    <p>{`Let's boil this all down into something we can remember: `}</p>
    <div className="formula">
Any grid item positioned to start or end on a line that goes in the same direction as the Grid will be given priority placement. Everything else will flow around it.
    </div>
    <h2>{`Order`}</h2>
    <p>{`You can change the order of grid items using the `}<inlineCode parentName="p">{`order`}</inlineCode>{` property, overriding the default document order placement. Let's use some dunes `}<span className="terrain dunes"></span>{` to show the item whose `}<inlineCode parentName="p">{`order`}</inlineCode>{` we're changing. Grid item order works exactly like `}<a parentName="p" {...{
        "href": "/post/flexbox-order/"
      }}>{`Flexbox order`}</a>{`, where items are `}<inlineCode parentName="p">{`order: 0`}</inlineCode>{` by default. Giving a grid item an order of 1 makes it show up at the end:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`dunes {
  order: 1;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gif/grid-item-placement/order-1.gif",
        "alt": "grid items with an order"
      }}></img><br parentName="p"></br>{`
`}{`Even though the dunes `}<span className="terrain dunes"></span>{` item was fourth in the document order, it had to wait all the way until the end to get placed in the Grid. The spec calls this `}<strong parentName="p">{`order-modified document order`}</strong>{`.`}</p>
    <h3>{`negative order`}</h3>
    <p>{`You can move grid items to the beginning by using a negative order, just like in Flexbox.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`dunes {
  order: -1;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gif/grid-item-placement/order-2.gif",
        "alt": "grid items with an order"
      }}></img></p>
    <h3>{`positioning before order`}</h3>
    <p>{`Ready for a twist? `}<strong parentName="p">{`Order takes a backseat to row start/end positioning`}</strong>{` (or column start/end positioning in a column flowing Grid) `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`rocky {
  grid-row: 1 / 3;
}

dunes {
  order: -1;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gif/grid-item-placement/order-3.gif",
        "alt": "grid items with an order"
      }}></img></p>
    <p>{`Even though our dunes `}<span className="terrain dunes"></span>{` item had a negative order, the rocky `}<span className="terrain rocky"></span>{` items with row positioning got first dibs.`}</p>
    <p>{`Items with that same-direction-as-grid positioning are Gold Card members and get to board first, no matter what. We can remember the priorities then with a simple rule:`}</p>
    <div className="formula">
  1. same-direction positioning<br />
  2. order property<br />
  3. document order
    </div>
    <h2>{`Sparse vs Dense Grid Auto Flow`}</h2>
    <p>{`Going back to this example that left empty cells in our grid:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`rocky {
  grid-column-start: 3;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gif/grid-item-placement/6.gif",
        "alt": "grid items with grid-column-start property set"
      }}></img></p>
    <h3>{`sparse packing`}</h3>
    <p>{`Let's take a look at the journey of that first rocky `}<span className="terrain rocky"></span>{` terrain item. It doesn't have a row start or end specified, so it has to wait its turn. The browser starts from the first item in the DOM and works its way down. It places the first grass `}<span className="terrain grass"></span>{` item in the first row, first column cell. Next item's turn. The browser sees that our rocky `}<span className="terrain rocky"></span>{` terrain wants to start in the 3rd column. So it `}<em parentName="p">{`skips forward`}</em>{` to the 3rd column, sees that this slot isn't already taken, and drops our rocky `}<span className="terrain rocky"></span>{` item into it. Next item's turn. The browser moves to the next cell to the right rather than going back to the previous cell even though there's an empty space in it. `}</p>
    <p>{`This behavior is called the `}<strong parentName="p">{`sparse`}</strong>{` packing algorithm and is the default for a Grid:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`planet {
  grid-auto-flow: row sparse; /* default */
}
`}</code></pre>
    <h3>{`dense packing`}</h3>
    <p>{`If we want the browser to `}<strong parentName="p">{`go back`}</strong>{` and fill empty cells rather than leave gaps, we can change to the `}<strong parentName="p">{`dense`}</strong>{` packing algorithm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`planet {
  grid-auto-flow: row dense;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gif/grid-item-placement/7.gif",
        "alt": "grid items with grid-column-start property set"
      }}></img></p>
    <p>{`Now the browser will go back to previous empty cells as it moves along, dropping items into the first available slot that will fit the item. Notice our second rocky `}<span className="terrain rocky"></span>{` item's column positioning is still honored.`}</p>
    <h2>{`Master Grid Layout`}</h2>
    <p>{`Grid layout is mind-blowing. It will change the way you code. Once you have it down you'll use it `}<strong parentName="p">{`everywhere`}</strong>{`.`}</p>
    <p>{`To get there you're going to need to know `}<em parentName="p">{`all`}</em>{` of the properties and their options like the back of your hand. You'll need to internalize how Grids work. There are a ton of concepts you'll need to fully understand. But mastery is absolutely worth it.`}</p>
    <p>{`I built `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` to give you mastery of `}<em parentName="p">{`all of it`}</em>{`. By playing the ten chapters you'll gain `}<em parentName="p">{`intuition`}</em>{`. You'll be able to simply build awesome UIs without having to think or struggle or look things up.`}</p>
    <p><a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Check it out`}</a>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      